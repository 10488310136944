export default class RequestUsersFilterDTO {

    public firstName: string | null = null;

    public lastName: string | null = null;

    public pageNumber = 1;

    public pageSize = 20;

    public userType = "ALL";

    public orderField = "ID";

    public orderDirection = "ASC";

    constructor(userType: string) {
        this.userType = userType;
    }

}