









































































































import {Component, Vue} from "vue-property-decorator";
import {namespace} from "vuex-class";
import UserDTO from "@/dto/UserDTO";
import UserDataDTO from "@/dto/UserDataDTO";
import ProfileService from "@/services/ProfileService";
import RequestUsersFilterDTO from "@/dto/auth/RequestUsersFilterDTO";
import {response} from "express";
import RegexUtils from "@/utils/RegexUtils";
import AdminLinksModal from "@/components/profile/admin/AdminLinksModal.vue";
import RouteNames from "@/router/RouteNames";
import Workspaces from "@/state/Workspaces";

const Auth = namespace("Auth");

@Component
export default class AdminBoard extends Vue {

    private loading = false;

    @Auth.Getter("isAdmin")
    private isAdmin!: boolean;

    @Auth.Getter("hasAdministrativePrivileges")
    private hasAdministrativePrivileges!: boolean;

    @Auth.State("user")
    private currentUser!: UserDTO

    private users: Array<UserDataDTO> = []

    private filter = new RequestUsersFilterDTO("ALL");

    private totalPages = 1;

    mounted() {
      Workspaces.resetWorkspace();
      if (!this.hasAdministrativePrivileges) {
          this.$router.push({name: RouteNames.HOME});
      } else if(this.$route.name === RouteNames.ADMIN) {
        this.$router.push({name: RouteNames.ADMIN_INDIVIDUALS_LIST});
      }
    }

    goToProfile(userId: number) {
        this.$router.push({name: "userprofile", params: {id: `${userId}`}})
    }

    openLinksModal(user: UserDataDTO) {
        this.$modal.show(
            AdminLinksModal,
            {
                user: user
            }
        );
    }

    loadUsers() {
        this.loading = true
        this.loadTotalPages();
        ProfileService.loadUsers(this.filter).then(
            (response) => {
                this.users = response.data;
                this.loading = false;
            },
            (error) => {
                const message =
                    (error.response && error.response.data) ||
                    error.message ||
                    error.toString();
                this.loading = false;
                return Promise.reject(message);
            }
        )
    }

    loadTotalPages() {
        ProfileService.loadUsersPagesCount(this.filter).then(
            response => {
                this.totalPages = response.data;
            },
            error => {
                const message =
                    (error.response && error.response.data) ||
                    error.message ||
                    error.toString();
                return Promise.reject(message);
            }
        );
    }

    handleName(event: KeyboardEvent) {
        if (event.key != '\'' && event.key != "-" && !RegexUtils.testLatin(event.key)) {
            event.preventDefault();
        }
    }

    clearFilter() {
        this.filter.firstName = null;
        this.filter.lastName = null;
        this.loadUsers();
    }

    changePage(page: number) {
        this.filter.pageNumber = page;
        this.loadUsers();
    }

    get getEmailSortIcon(): string {
        return this.getSortIcon("EMAIL");
    }

    get getFirstNameSortIcon(): string {
        return this.getSortIcon("FIRST_NAME");
    }

    get getLastNameSortIcon(): string {
        return this.getSortIcon("LAST_NAME");
    }

    get getCompanyNameSortIcon(): string {
        return this.getSortIcon("COMPANY_NAME");
    }

    getSortIcon(field: string) {
        if (this.filter.orderField !== field) {
            return "sort";
        } else if (this.filter.orderDirection === "ASC") {
            return "sort-up";
        } else {
            return "sort-down";
        }
    }

    switchOrder(field: string) {
        if (field === this.filter.orderField) {
            this.toggleDirection();
        } else {
            this.filter.orderField = field;
            this.filter.orderDirection = "ASC";
        }
        this.loadUsers();
    }

    toggleDirection() {
        this.filter.orderDirection = this.filter.orderDirection === "ASC" ? "DESC" : "ASC";
    }

}

